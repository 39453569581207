<script setup lang="ts">
const { page } = useContent()
</script>

<template>
    <div class="flex flex-col min-h-screen">
        <HeroSection />
        <div class="content-grid">
            <slot />
            <!-- <StaticMarkdownRender path="/" /> -->
        </div>
    </div>
</template>


<style lang="scss">
.content-grid> :first-child {
    --gap: clamp(1rem, 6vw, 1.5rem);
    --full: minmax(var(--gap), 1fr);
    --content: min(80rem, 100% - var(--gap) * 2);
    --popout: minmax(0, 2rem);
    --feature: minmax(0, 5rem);

    display: grid;
    grid-template-columns:
        [full-start] var(--full) [feature-start] var(--feature) [popout-start] var(--popout) [content-start] var(--content) [content-end] var(--popout) [popout-end] var(--feature) [feature-end] var(--full) [full-end];
}

.content-grid> :first-child>* {
    grid-column: content;
}

.content-grid--popout {
    grid-column: popout !important;
}

.content-grid--content {
    grid-column: content !important;
}

.content-grid--frature {
    grid-column: feature !important;
}

.content-grid--full {
    grid-column: full !important;
}
</style>
